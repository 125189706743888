import React, { useState } from "react"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link }from "gatsby"

const Menu = (props) => {
    const [toggle, setToggle] = useState(false)

    const data = props.data

    function handleDropdown(e){  
        e.preventDefault();
        setToggle(!toggle)
     }



    return(
        
        <section id="MenuSection">
            <menu id="menu">
                <Link to="/" className="logo"> <img src="https://cdn.mullenloweus.agency/ninthbrain/images/octologo.png" alt="logo"  /></Link>
                <div className="link-text">
                    <AnchorLink href='#trends' className="menu-text">Macro Trends</AnchorLink>
                    <AnchorLink href='#articles' className="menu-text">Articles</AnchorLink>
                    <a href='/' onClick={handleDropdown} className="menu-text"><span className="dropdownMenu">Archive</span></a>
                       
                        {toggle && (
                            <div className="dropwdownlist">
                                <ul>
                                    {data.map(list => (
                                    
                                    list.node.menuList.map((link, idx) => (
                                        <li key={link.slug}>
                                            <Link to={'/archives/' + link.slug}>{link.menuText}</Link>
                                        </li>
                                        ))

                                    ))}
                                </ul>
                            </div>
                        )}
                
                </div>           
            </menu>
        </section>
    )
}



export default Menu
