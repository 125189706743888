import React from "react"


const Articles = (props) => {
    const data = props.data;
    const contentData = props.contentData

    const title = contentData.map(text => {
        return text.node.articleGroupText.articleMidText
    })


    return (
    <section id="articles">
        <div className="article-group">
            <div className="article-text-group">
                <h3>{title}</h3>
            </div>
            <div className="article-content-group">
             {data.map(articles => {
             
                const {title, id, HalfSizeImage, RightSideTextContent, TwoColContent, FullSizeImage} = articles.node
                 
                return(
                <div key={id} className="article-content">
                    <h2 id={title.replace(/\s+/g, '')}>{title}</h2>
                    
                    <div className="leftImageRightText articleContentWidth">
                        <div  className="left-image">
                            { HalfSizeImage && HalfSizeImage.HalfImage && HalfSizeImage.HalfImage.url && (
                                <img src={HalfSizeImage.HalfImage.url} alt="pic"/>
                            )}  
                        </div>
                        <div className="right-text">
                            <h4>{RightSideTextContent.BoldText}</h4>
                            <div dangerouslySetInnerHTML={{ __html: RightSideTextContent.RightText.replace(/href/g, "target='_blank' href") }}/>
                        </div>
                    </div>
                     <div className="two-column articleContentWidth"   dangerouslySetInnerHTML={{ __html: TwoColContent.ColumnText.replace(/href/g, "target='_blank' href") }}/>
            
                    <div className="full-width-image articleContentWidth">
                
                        { FullSizeImage && FullSizeImage.ImageContent && FullSizeImage.ImageContent.url && (
                                <img src={FullSizeImage.ImageContent.url } alt="pic"/>
                          
                        )}  
                    </div>
                </div>
                )
                })} 
            </div>
        
         
        </div>
      </section>
    )
  }




export default Articles



