import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
//import { useStaticQuery, graphql } from "gatsby"


const Macrotrends = (props) => {
    const data = props.data;
    
    const firstText = data.map(text => {
      return text.node.Macrotrends.MacroTrendTitleText
    })
    const description = data.map(text => {
      return text.node.Macrotrends.MacroTrendLongTextDescription
    })
   

    return (
    <section id="trends">
        <div className="trend-text-group">
            <div className="left-side">
            <h2>{firstText}</h2>
            </div>
            <div className="right-side">
              <div className="description" dangerouslySetInnerHTML={{ __html: description }}/>
              <ul className="list-group-items">
              {data.map(list => (
                list.node.Macrotrends.MacroTrendList.map(list_under => (
                  <li key={list_under.id}>
                      <div className="list-text-group">
                        <h2>{list_under.ListTextTitle}</h2>
                   
                        <div dangerouslySetInnerHTML={{ __html: list_under.ListDescription }}/>
                      </div>
                      <AnchorLink href={`#${list_under.JumplinkArticle.title.replace(/\s+/g, '')}`}>
                        <img
                          src="https://cdn.mullenloweus.agency/ninthbrain/images/white-arrow-down.svg"
                          alt="white arrow down button"
                        />
                      </AnchorLink>
                    
                  </li>
                ))

              ))}
              
            </ul>
          </div>
        </div>
      </section>
    )
  }




export default Macrotrends
