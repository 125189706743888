import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Macrotrends from "../components/macrotrends"
import Menu from "../components/menu"
import Articles from "../components/articles"

const IndexPage = ({data}) => {
  const articlesdata = data.allStrapiArticle.edges;
  const homecontentdata = data.allStrapiHomepage.edges;
 
  return (
  <Layout data={homecontentdata}>
    <SEO title="Home" />
    <Menu data={homecontentdata} />
    <Hero data={homecontentdata}/>
    <Macrotrends data={homecontentdata}/>
    <Articles data={articlesdata} contentData={homecontentdata}/> 
  </Layout>
  )
}

export default IndexPage


export const pageQuery = graphql `
  query IndexQuery {
    allStrapiArticle(sort: {fields: Position}){
      edges {
        node {
          title
          id
          HalfSizeImage {
            HalfImage {
              url
            }
          }
          FullSizeImage {
            ImageContent {
              url
            }
          }
          RightSideTextContent {
            RightText
            BoldText
          }
          TwoColContent {
            ColumnText
          }
        }
      }
    }
  
    allStrapiHomepage {
      edges {
        node {
          articleGroupText {
            articleEyeBrowText
            articleLongText
            articleMidText
          }
          menuList {
            menuText
            slug
          }
          HeroContent {
            FirstHeroTextTitle
            SecondHeroTextTitle
            HeroDescriptionText
            HeroBackgroundImage {
              url
              id
            }
            LargeNinthBrainLogo{
              url
            }
          }
          Macrotrends {
            MacroTrendTitleText
            MacroTrendLongTextDescription
            MacroTrendList {
              ListTextTitle
              ListDescription
              id
              JumplinkArticle{
                id
                title
              }
            }
          }
          FeaturedCollections {
            FeaturedSecondText
            FeaturedFirstText
            FeaturedDescription
            CarouselImage {
              carouselImage {
                url
                id
              }
            }
          }
        }
      }
    }
  }
`