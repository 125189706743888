import * as React from "react"

const Hero = (props) => {

const data = props.data;

const description = data.map(text => {
    return text.node.HeroContent.HeroDescriptionText
})

// const backgroundIMG = data.map(img => {
//     return img.node.HeroContent.HeroBackgroundImage.url
// })

const LargeNinthBrainLogo = data.map(img => {
    return img.node.HeroContent.LargeNinthBrainLogo.url
})

  return (
        <section id="hero">
            <div className="content--width">
                <h1 className="big-text-group"> <img src={LargeNinthBrainLogo} alt="scroll down button"  /></h1>
                <p className="hero-description-text">{description}</p>
                <div className="hero-scroll-down-btn">
                    <span>Scroll</span>
                        <img src="https://cdn.mullenloweus.agency/ninthbrain/images/scroll-icon.svg" alt="scroll down button"  />
                    <span>Down</span>
                </div>
              
            </div>
        </section>
    )
}

export default Hero
